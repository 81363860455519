import '../styles/app.scss';

import $ from "jquery";
import "what-input";
// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
//require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the
// above and uncomment the line below
import './foundation-explicit-pieces';

$(document).foundation();

// Загрузка полных фонтов с хинтами
if( "fonts" in document) {
  let fdir = window.geoenv_fontdir;
  var regular = new FontFace("Raleway",
    `url(${fdir}Raleway-Regular-hint-all.woff2) format('woff2'), \
     url(${fdir}Raleway-Regular-hint-all.woff) format('woff'), \
     url(${fdir}Raleway-Regular.ttf) format('truetype')`);
  var bold = new FontFace("Raleway",
    `url(${fdir}Raleway-Bold-hint-all.woff2) format('woff2'), \
     url(${fdir}Raleway-Bold-hint-all.woff) format('woff'), \
     url(${fdir}Raleway-Bold.ttf) format('truetype')`, { weight: "700" });
  // var pfd_bold = new FontFace("Playfair Display",
  //    `url(${fdir}PlayfairDisplay-Bold-hint-all.woff2) format('woff2'), \
  //     url(${fdir}PlayfairDisplay-Bold-hint-all.woff) format('woff'), \
  //     url(${fdir}PlayfairDisplay-Bold.ttf) format('truetype')`, { weight: "700" });
  var oswald_bold = new FontFace("Oswald",
      `url(${fdir}Oswald-Bold-hint-all.woff2) format('woff2'), \
       url(${fdir}Oswald-Bold-hint-all.woff) format('woff'), \
       url(${fdir}Oswald-Bold.ttf) format('truetype')`, { weight: "700" });

  // eslint-disable-next-line
  Promise.all([ bold.load(), regular.load(), // pfd_bold.load(),
                oswald_bold.load() ]).then(function(fonts) { // no-undef
    fonts.forEach(function(font) {
        document.fonts.add(font);
    });
  });

}
